import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import loadable from '@loadable/component';
import { Grid, Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const GeneralTestimonial = loadable(() => import('./GeneralTestimonial'));
const TestimonialCarousel = loadable(() => import('./TestimonialCarousel'));
const SingleTestimonial = loadable(() => import('./SingleTestimonial'));

const useStyles = makeStyles((theme) => ({
	testimonialTitle: {
		textAlign: 'center',
		marginBottom: '2rem',
		marginTop: '2rem',
		[theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
		},
	},
}));

export const Testimonials = ({ header, testimonials, formRef, background }) => {
	const classes = useStyles();

	const [selected, setSelected] = useState(0);
	const [rotating, setRotating] = useState(true);
	const [carouselTestimonialArray, setCarouselTestimonialArray] =
		useState(testimonials);
	const med = useMediaQuery('(max-width: 960px)');

	useEffect(() => {
		let rotationId;

		if (rotating) {
			rotationId = setInterval(() => {
				setSelected((selected) =>
					selected < testimonials.length - 1 ? selected + 1 : 0
				);
			}, 10000);
		}

		return () => clearInterval(rotationId);
	}, [selected, rotating, testimonials]);

	const handleTestimonialForward = useCallback(() => {
		setRotating(false);
		setSelected((selected) =>
			selected === testimonials.length - 1 ? 0 : selected + 1
		);
		setRotating(true);
	}, [testimonials]);

	const handleTestimonialReverse = useCallback(() => {
		setRotating(false);
		setSelected((selected) =>
			selected === 0 ? testimonials.length - 1 : selected - 1
		);
		setRotating(true);
	}, [testimonials]);

	return (
		<Container>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'>
				<Grid item xs={12} md={10}>
					<Typography
						variant='h2'
						color='primary'
						style={{ color: background && 'white' }}
						className={classes.testimonialTitle}>
						{header}
					</Typography>
				</Grid>
			</Grid>

			{testimonials.length === 1 && !med ? (
				<SingleTestimonial testimonial={testimonials[0]} />
			) : (
				<>
					{med ? (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'>
							<TestimonialCarousel
								carouselTestimonialArray={carouselTestimonialArray}
								ref={formRef}
							/>
						</Grid>
					) : (
						<>
							{testimonials.map((testimonial, index) => (
								<GeneralTestimonial
									testimonial={testimonial}
									testimonialHeader={header}
									key={index}
									index={index}
									selected={selected}
									ref={formRef}
									handleTestimonialForward={handleTestimonialForward}
									handleTestimonialReverse={handleTestimonialReverse}
									fade={true}
								/>
							))}
						</>
					)}
				</>
			)}
		</Container>
	);
};
