import React from 'react';

import {
	Typography,
	withStyles,
	useTheme,
	makeStyles,
} from '@material-ui/core';

const StyledBody = withStyles((theme) => ({
	root: {
		lineHeight: 1.1,
    margin: '1rem 0',
		fontFamily: 'Roboto',
	},
}))(Typography);

const HeroBody = ({ children, leadership, industry, whyServman }) => {
	const useStyles = makeStyles((theme) => ({
		heroBody1: {
			color: theme.white,
			fontWeight: 400,
			fontSize: '1.875rem',
			fontStyle: 'normal',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.5rem',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '1.25rem',
			},
		},
		heroBody2: {
			color: theme.white,
			fontWeight: 400,
			fontSize: '2rem',
			fontStyle: 'normal',
			[theme.breakpoints.down('md')]: {
				fontSize: '1.875rem',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.5rem',
        textAlign: 'left',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '1.25rem',
			},
		},
    heroBody3: {
      color: theme.white,
      fontWeight: 400,
      fontSize: '2rem',
      fontStyle: 'normal',
      marginBottom: '4rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.875rem',
        marginBottom: '0rem',
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },
	}));

	const classes = useStyles();
	return (
		<StyledBody
			variant='body1'
			className={
				leadership || industry ? classes.heroBody1 : whyServman ? classes.heroBody3 : classes.heroBody2
			}>
			{children}
		</StyledBody>
	);
};

const StyledListItem = withStyles((theme) => ({
	root: {
		lineHeight: 1.3,
		fontSize: '1.25rem',
		color: theme.mediumDarkGray,
		fontFamily: 'Roboto',
	},
}))(Typography);

const ListItem = ({ children }) => {
	return <StyledListItem variant='body1' role="heading">{children}</StyledListItem>;
};

const StyledBodyCopy = withStyles((theme) => ({
	root: {
		fontSize: '1.125rem',
		//18px
		lineHeight: '1.688rem',
		fontFamily: 'Roboto',
		fontWeight: 400,
		color: '#4B5B69',
		letterSpacing: '0.15px',
	},
}))(Typography);

const BodyCopy = ({ children }) => (
	<StyledBodyCopy variant='body1'>{children}</StyledBodyCopy>
);

export { HeroBody, ListItem, BodyCopy };
